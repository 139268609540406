import * as React from 'react';
import Link from 'gatsby-link';
// import Helmet from 'react-helmet';

const menu = [
  {
    title: 'Home',
    to: '/',
  },
  {
    title: 'Pitch Nights 2021',
    to: '/pitch-nights-2021',
  },
  {
    title: 'FAQ',
    to: '/frequently-asked-questions',
  },
  {
    title: 'About',
    to: '/about',
  },
  { title: 'Contact', to: '/contact' },
  {
    title: 'Apply for a grant',
    to: 'https://www.awesomefoundation.org/en/submissions/new?chapter=thehague',
  },
];
export const Header: React.FC = () => {
  return (
    <header className="bg-white text-center p-4">
      <h1 className="mb-0 text-lg leading-tight">
        <Link to="/" className="text-black hover:text-primary">
          AWESOME
          <br />
          THE HAGUE
        </Link>
      </h1>
      <nav className="my-4">
        <ul>
          {menu.map((item) => (
            <li
              className="text-sm p-2 hover:underline inline-block"
              key={item.title}
            >
              <Link to={item.to} activeClassName="underline text-black">
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
};
