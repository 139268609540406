import * as React from 'react';
import Link from 'gatsby-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTelegram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

export const Footer: React.FC = () => {
  return (
    <header className="bg-white text-center py-8">
      <div className="mb-8">
        <a href="https://facebook.nl" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faFacebook} size="2x" className="m-4" />
        </a>
        <a
          href="https://twitter.com/awesomethehague"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faTwitter} size="2x" className="m-4" />
        </a>
        <a
          href="https://instagram.com/awesomethehague"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} size="2x" className="m-4" />
        </a>
        <a
          href="https://linkedin.com/company/awesomethehague"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedin} size="2x" className="m-4" />
        </a>
        <a
          href="https://t.me/joinchat/AHrH1hlCr1e2JzMbO0X5xw"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faTelegram} size="2x" className="m-4" />
        </a>
      </div>
      <p className="text-sm">
        <span className="p-2">
          <Link to="/privacy-policy">Privacy policy</Link>
        </span>
        &bull;
        <span className="p-2">
          <Link to="/cookie-policy">Cookie policy</Link>
        </span>
        &bull;
        <span className="p-2">
          <Link to="/disclaimer">Disclaimer</Link>
        </span>
      </p>
      <p className="text-sm text-gray-400">
        © 2020 Awesome Foundation The Hague. All Rights Reserved. A website by
        Urbanlink
      </p>
    </header>
  );
};
